<dpa-modal
  [modalOpen]="isOpen"
  (onModalClose)="onCancelClick()"
>
  <dpa-modal-title>
    {{ title }}
  </dpa-modal-title>
  <div class="modal-body">
    @if (alertMsg) {
      <clr-alert
        clrAlertType="warning"
        [clrAlertClosable]="false"
      >
        <clr-alert-item class="alert-item">
          <span class="alert-text">
            {{ alertMsg }}
          </span>
        </clr-alert-item>
      </clr-alert>
    }
    <p>
      {{ description | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button
      (click)="onCancelClick()"
      type="button"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      (click)="onDeleteClick()"
      type="button"
      class="btn btn-danger"
    >
      {{ 'COMMON_ACTIONS.DELETE' | translate }}
    </button>
  </div>
</dpa-modal>
